<style lang="less" scoped>
#adminlist {
  .tab-top {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }
  .select-status {
    width: 150px;
    margin-right: 14px;
  }
  .model-action {
    font-size: 14px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: rgba(68, 125, 245, 1);
    &:hover {
      cursor: pointer;
    }
  }
}
</style>
<style>
.ant-table-tbody {
  /* font-size: 10px !important; */
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500 !important;
  color: rgba(51, 51, 51, 1) !important;
}
.ant-table-row {
  height: 37px !important;
}
</style>
<template>
  <div class="adminlist" id="adminlist">
    <div class="tab-top">
      <table-title />
      <table-action alias="adminlist" :hasSearch="false" @success="onSuccess">
        <a-select placeholder="请选择状态" class="select-status" slot="custom" @change="selectOrderStatus">
          <a-select-option :key="0">全部</a-select-option>
          <a-select-option :key="1">超级管理员</a-select-option>
          <a-select-option :key="2">普通管理员</a-select-option>
          <a-select-option :key="3">销售人员</a-select-option>
          <a-select-option :key="4">编程人员</a-select-option>
          <a-select-option :key="5">项目经理</a-select-option>
          <a-select-option :key="6">研究人员</a-select-option>
          <a-select-option :key="7">DP</a-select-option>
        </a-select>
      </table-action>
    </div>
    <a-table
      :columns="columns"
      :loading="loading"
      :dataSource="data"
      :pagination="{
        current, 
        total,
        pageSize: size, 
      }"
      @change="tabChange"
      style="margin-top: 13px"
    >
      <div slot-scope="record" slot="action">
        <span class="model-action" @click="onEdit(record.id)">修改</span>
        <a-divider v-show="record.admin_status=='DISABLE'" type="vertical" />
        <span v-show="record.admin_status=='DISABLE'" class="model-action" @click="comfirmEnable(record.id)">恢复</span>
        <a-divider v-show="record.admin_status=='ENABLE'" type="vertical" />
        <table-delete v-show="record.admin_status=='ENABLE'" @comfirm="comfirmDelete(record.id)"></table-delete>
        <a-divider type="vertical" />
        <admin-reset-pwd :reset_admin_id="record.id"></admin-reset-pwd>
      </div>
    </a-table>
  </div>
</template>

<script>
import { fetchAdminlist, updateAdmin } from "@/api/admin";
const columns = [
  {
    title: "ID",
    dataIndex: "id",
  },
  {
    title: "账号",
    dataIndex: "admin_account",
  },
  {
    title: "用户名称",
    dataIndex: "admin_name",
  },
  {
    title: "用户邮箱",
    dataIndex: "email",
  },
  {
    title: "用户角色",
    dataIndex: "role_text",
  },
  {
    title: "用户状态",
    dataIndex: "admin_status_text",
  },
  {
    title: "创建时间",
    dataIndex: "create_at",
  },
  {
    title: "操作",
    key: "action",
    scopedSlots: { customRender: "action" },
  }
];

export default {
  name: "AdminList",
  components: {
    "admin-reset-pwd": resolve =>
      require(["@/components/model/AdminResetPwd.vue"], resolve),
    "table-title": resolve =>
      require(["@/components/table/TableTitle.vue"], resolve),
    "table-action": resolve =>
      require(["@/components/table/TableAction.vue"], resolve),
    "table-delete": resolve =>
      require(["@/components/table/TableDelete.vue"], resolve)
  },
  data() {
    return {
      data: [],
      columns,
      total: 10,
      size: 10,
      current: 1,
      selectStatus: undefined,
      loading: true
    };
  },
  created() {
    this.getAdminlist();
  },
  methods: {
    async getAdminlist() {
      const that = this;
      this.loading = true;
      try {
        let res = await fetchAdminlist({
          page_size: this.size,
          page: this.current,
          role: this.selectStatus,
        });
        if (!res) return;
        this.loading = false;
        this.total = res.total;
        this.data = res.admin_list.map((item, index) => {
          switch (item.role_id) {
            case 1:
              item.role_text = "超级管理员";   
              break;
            case 2:
              item.role_text = "普通管理员";   
              break;
            case 3:
              item.role_text = "销售人员";   
              break;
            case 4:
              item.role_text = "编程人员";   
              break;
            case 5:
              item.role_text = "项目经理";   
              break;
            case 6:
              item.role_text = "研究人员";   
              break;
            case 7:
              item.role_text = "DP";   
              break;
            default:
              break;
          }
          switch (item.admin_status) {
            case "DISABLE":
              item.admin_status_text = "已删除";
              break;
            case "ENABLE":
              item.admin_status_text = "正常";
              break;
            default:
              break;
          }
          item["key"] = item.id;
          return item;
        });
      } catch (err) {
        this.loading = false;
        console.log(err);
      }
    },
    tabChange(pagination, filters, sorter, { currentDataSource }) {
      let { current, pageSize } = pagination;
      this.current = current;
      this.size = pageSize;
      this.getAdminlist();
    },
    onSuccess(e) {
      this.$router.push({ name: "adminDetail" });
    },
    selectOrderStatus(e) {
      this.selectStatus = e;
      if (e == 0) {
        this.selectStatus = undefined;  
      }
      this.current = 1;
      this.getAdminlist();
    },
    onEdit(adminId) {
      this.$router.push({ name: "adminDetail", query: { adminId: adminId }});
    },
    comfirmDelete(adminId) {
      this.deleteAdmin(adminId, "DISABLE")
    },
    comfirmEnable(adminId) {
      this.deleteAdmin(adminId, "ENABLE")
    },
    async deleteAdmin(adminId, status) {
      this.loading = true;
      try {
        await updateAdmin({
          id: adminId,
          admin_status: status
        });
        this.$message.success("删除成功");
        this.loading = false;
        this.getAdminlist();
      } catch (error) {
        this.loading = false;
        console.log(error)
      }
    },
  }
};
</script>
